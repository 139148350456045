import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class RelatoriService extends baseRestService {

    constructor() {
        super();

        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string, params?: relatore.queryParams): Promise<number> {
        return await this.Get(`/api/corsi/${corsoId}/relatori/count`, params);
    }

    async List(corsoId: string, params?: relatore.queryParams): Promise<relatore.item[]> {
        return await this.Get(`/api/corsi/${corsoId}/relatori`, params);
    }

    async Detail(corsoId: string, id: string): Promise<relatore.item> {
        return await this.Get(`/api/corsi/${corsoId}/relatori/${id}`);
    }

    async Set(corsoId: string, item: relatore.item): Promise<relatore.item> {
        return await this.Post(`/api/corsi/${corsoId}/relatori`, item);
    }

    async Remove(corsoId: string, id: string) {
        return await this.Delete(`/api/corsi/${corsoId}/relatori/${id}`);
    }
}

export const relatoriService = new RelatoriService();