import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { authService } from '@/modules/account/services/authService';
import { loginStore } from '@/store/modules/login.store';
import { iscrittoService } from '../../services/iscrittoService';
import { enteService } from '@/modules/enti/service/enteService';
import { crogService } from '@/modules/enti/service/crogService';
import { etfService } from '@/modules/enti/service/etfService';
import { ctsService } from '@/modules/enti/service/ctsService';
import { periodoFormativoService } from '@/modules/offertaFormativa/services/periodoFormativoService';
import { store } from '@/store';
import { formatTime } from '@/filters';

@Component({
  name: "ListaCorsiGiornalista"
})
export default class ListaCorsiGiornalista extends Vue {
  @Prop({ required: true, default: () => [] })
  corsi: giornalista.corso.item[];

  @Prop({ default: 'Nessun corso' })
  emptyText: string;

  loading: boolean = false;
  enti: Map<string, ente.item> = new Map<string, ente.item>();
  mapPeriodiFormativi: Map<string, periodoFormativo.item> = new Map<string,periodoFormativo.item>();
  
  get periodiFormativi(): periodoFormativo.item[] {
    return store.getters.periodiFormativi.list();
  };

  async created() {
    this.loading = true;
    let enti: ente.item[] = [];
    enti = enti.concat(await ctsService.List() as any);
    enti = enti.concat(await crogService.List() as any);
    enti = enti.concat(await etfService.List() as any);

    for (const e of enti) {
      if (!this.enti.has(e.id)) {
        this.enti.set(e.id, e);
      }
    }

    for (const periodoFormativo of this.periodiFormativi) {
      if(!this.mapPeriodiFormativi.has(periodoFormativo.id)){
        // result.set(periodoFormativo.id, periodoFormativo.nome);
        this.mapPeriodiFormativi.set(periodoFormativo.id, periodoFormativo);
      }
    } 
    
    await this.laodIscrizioniCorso();
    await this.loadIscrizioneGiornalista();
    this.loading = false;
    
  }
  
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  @Watch("corsi")
  async changeCorsi() {
    this.loading = true;
    await this.laodIscrizioniCorso();
    await this.loadIscrizioneGiornalista();
    this.loading = false;
  }

  iscrizioniCorso: Map<string, number> = new Map<string, number>();
  async laodIscrizioniCorso() {
    if(!this.corsi || this.corsi.length <= 0) return;
    for (const corso of this.corsi) {
      if (!this.iscrizioniCorso.has(corso.id)) {
        const count = await iscrittoService.Count(corso.id, { cancellato: false });
        this.iscrizioniCorso.set(corso.id, count);
      }
    }
  }

  iscrizioneGiornalista: Map<string, iscritto.item> = new Map<string, iscritto.item>();
  async loadIscrizioneGiornalista() {
    this.loading = true;
    const user = loginStore.state.userInfo || await authService.WhoAmI();
    if (user && user.anagraficaGiornalistaId) {
      const iscrizioniGiornalista = await iscrittoService.Giornalista(user.anagraficaGiornalistaId);
      if (iscrizioniGiornalista && iscrizioniGiornalista.length > 0) {
        iscrizioniGiornalista.forEach((f) => {
          if (!this.iscrizioneGiornalista.has(f.corsoId)) this.iscrizioneGiornalista.set(f.corsoId, f);
        });
      }
    }
  }

  getIcon(corsoId: string) {
    const iscrizione = this.iscrizioneGiornalista.get(corsoId);

    if (iscrizione.ammesso && !iscrizione.cancellato) {
      return "el-icon-success";
    }

    if (iscrizione.cancellato) {
      return "el-icon-error";
    }

    if (!iscrizione.cancellato && !iscrizione.ammesso) {
      return "el-icon-success";
    }

    return null;
  }

  getIconColor(corsoId: string) {
    const iscrizione = this.iscrizioneGiornalista.get(corsoId);

    if (iscrizione.ammesso && !iscrizione.cancellato) {
      return "#13ce66";
    }

    if (iscrizione.cancellato) {
      return "#ff4949";
    }

    if (!iscrizione.cancellato && !iscrizione.ammesso) {
      return "#1890ff";
    }

    return null;
  }

  getDataFineIscrizione(c: giornalista.corso.item){
    var t = new Date(c.dataFineIscrizione)
    var d = new Date(t.setDate(t.getDate() - 1));
    return formatTime(d, 'DD/MM/YYYY')
  }

  getIconTooltip(corsoId: string) {
    const iscrizione = this.iscrizioneGiornalista.get(corsoId);

    if (iscrizione.ammesso && !iscrizione.cancellato) {
      return "Ammesso al corso";
    }

    if (iscrizione.cancellato) {
      return "Iscrizione cancellata";
    }

    if (!iscrizione.cancellato && !iscrizione.ammesso) {
      return "Iscrizione in lista di attesa";
    }

    return null;
  }
}
