import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import RightPanel from "@/components/RightPanel/index.vue";

import enteAutocomplete from '@/modules/enti/components/enteAutocomplete.vue';
import referenteAutocomplete from '@/modules/corsi/components/referenteAutocomplete/referenteAutocomplete.vue';
import {store} from '@/store'

@Component({
  components: {
    RightPanel,
    enteAutocomplete,
    referenteAutocomplete,
  }
})

export default class filterCorsi extends Vue {

  @Prop({ required: true })
  query: giornalista.corso.queryParams;

  erogazione: number = 0;

  get filterActive(): boolean {
    this.erogazione = 
      store.state.corsiAperti.query.continuativo?3:
      store.state.corsiAperti.query.virtuale?2:
      (store.state.corsiAperti.query.virtuale === false 
        && store.state.corsiAperti.query.continuativo === false )?1:0;
    return (this.query &&
      (
        !!this.query.search ||
        !!this.query.referenteId ||
        !!this.query.enteId ||
        (this.query.deontologico === true || this.query.deontologico === false) ||
        (this.query.gratuito === true || this.query.gratuito === false) ||
        (this.erogazione > 0)
      ));
  }

  @Watch("erogazione")
  erogazioneChange(n: number, o: number) {
    if (n !== undefined && n !== null && n !== o) {
      switch (n) {
        case 1: {
          this.query.continuativo = false;
          this.query.virtuale = false;
          break;
        }
        case 2: {
          this.query.continuativo = false;
          this.query.virtuale = true;
          break;
        }
        case 3: {
          this.query.continuativo = true;
          this.query.virtuale = null;
          break;
        }
        default:{
          this.query.continuativo = null;
          this.query.virtuale = null;
          break;
        }
      }
    }
  }

  handleFilter() {
    this.query.page = 1;
    (this.$refs.filterPanel as any).show = false;
    this.$emit('change', this.query);
  }

  handleClean() {
    (this.$refs.filterPanel as any).show = false;
    this.erogazione = 0;
    this.$emit('clean', {
      search: null,
      referenteId: null,
      enteId: null,
      deontologico: null,
      gratuito: null,
      continuativo: null,
      virtuale: null,
      orderField: this.query.orderField,
      orderDescending: this.query.orderDescending,
      page: 1,
      take: 20
    } as corso.queryParams);
  }
}