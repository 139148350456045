import { Component, Vue } from 'vue-property-decorator';
import { giornalistiCorsoService } from '../services/giornalistiCorsoService';
import { capitalizeFirstLetter } from '@/utils/utils';

import {store} from '@/store'

import filterCorsi from '../components/filterCorsi/filterCorsi.vue';
import listaCorsiGiornalista from '../components/listaCorsiGiornalista/listaCorsiGiornalista.vue';

@Component({
  components: {
    filterCorsi,
    listaCorsiGiornalista,
  }
})
export default class CorsiViewGiornalista extends Vue {
  user: user.item = null;
  list: giornalista.corso.item[] = [];

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [10, 20, 50],
    total: 0
  };

  get query(): giornalista.corso.queryParams{return store.state.corsiAperti.query}

  async created() {
    await this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.pagination.total = await giornalistiCorsoService.CountCorsiAperti(this.query);
    this.list = (await giornalistiCorsoService.ListCorsiAperti(this.query)) as giornalista.corso.iscritto[];
    this.loading = false;
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query) {
    store.actions.corsiAperti.setQuery(query);
    this.fetchData();
  }

  onFilterClean(query) {
    store.actions.corsiAperti.setQuery(query);
    this.fetchData();
  }
}
