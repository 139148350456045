import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class SessioneService extends baseRestService {

    constructor() {
        super();
        this.baseUrl = () => settings.baseUrl;
    }

    async Count(corsoId: string, params?: sessione.queryParams): Promise<number> {
        return await this.Get<number>(`/api/corsi/${corsoId}/sessioni/count`, params);
    }

    async List(corsoId: string, params?: sessione.queryParams): Promise<sessione.item[]> {
        return await this.Get<sessione.item[]>(`/api/corsi/${corsoId}/sessioni`, params);
    }

    async Detail(corsoId: string, id: string): Promise<sessione.item> {
        return await this.Get<sessione.item>(`/api/corsi/${corsoId}/sessioni/${id}`);
    }

    async Set(corsoId: string, obj: sessione.item): Promise<sessione.item> {
        return await this.Post<sessione.item>(`/api/corsi/${corsoId}/sessioni`, obj);
    }

    async Remove(corsoId: string, id: string): Promise<void> {
        return await this.Delete(`/api/corsi/${corsoId}/sessioni/${id}`);
    }
}

export const sessioneService = new SessioneService();