import { baseRestService } from "@/services/_base/baseRestService";
import settings from "@/settings";

class ModuliService extends baseRestService {

    constructor() {
        super();

        this.baseUrl = () => settings.baseUrl;
    }

    async List(corsoId: string): Promise<modulo.item[]> {
        return await this.Get(`/api/corsi/${corsoId}/moduli`);
    }

    async Set(corsoId: string, modulo: modulo.item): Promise<modulo.item> {
        return await this.Post(`/api/corsi/${corsoId}/moduli`, modulo);
    }

    async Remove(corsoId: string, idModulo: string) {
        return await this.Delete(`/api/corsi/${corsoId}/moduli/${idModulo}`);
    }
}

export const moduliService = new ModuliService();