import { Component, Vue } from 'vue-property-decorator';
import { authService } from '@/modules/account/services/authService';
import { giornalistiCorsoService } from '../services/giornalistiCorsoService';
import { loginStore } from '@/store/modules/login.store';
import { capitalizeFirstLetter } from '@/utils/utils';

import filterCorsi from '../components/filterCorsi/filterCorsi.vue';
import listaCorsiGiornalista from '../components/listaCorsiGiornalista/listaCorsiGiornalista.vue';

@Component({
  components: {
    filterCorsi,
    listaCorsiGiornalista,
  }
})
export default class CorsiViewGiornalista extends Vue {
  user: user.item = null;
  list: giornalista.corso.item[] = [];

  pagination: { pageSizes: number[]; total: number } = {
    pageSizes: [10, 20, 50],
    total: 0
  };

  query: giornalista.corso.queryParams = {
    search: null,
    referenteId: null,
    enteId: null,
    deontologico: null,
    gratuito: null,
    continuativo: null,
    virtuale: null,
    orderField: 'Data',
    orderDescending: true,
    page: 1,
    take: 10,
  };

  async created() {
    await this.fetchData();
  }

  loading: boolean = false;
  async fetchData() {
    this.loading = true;
    this.user = loginStore.state.userInfo || await authService.WhoAmI();
    if (this.user && this.user.anagraficaGiornalistaId) {
      this.pagination.total = await giornalistiCorsoService.CountIMieiCorsi(this.user.anagraficaGiornalistaId, this.query);
      this.list = await giornalistiCorsoService.ListIMieiCorsi(this.user.anagraficaGiornalistaId, this.query);
    }
    this.loading = false;
  }

  paginationSizeChange(size) {
    this.query.take = size;
    this.query.page = 1;
    this.fetchData();
  }

  paginationPageChange(page) {
    this.query.page = page;
    this.fetchData();
  }

  sortChange({ column, prop, order }) {
    this.query.orderField = capitalizeFirstLetter(prop);
    this.query.orderDescending = order === 'descending';
    this.fetchData();
  }

  onFilterChange(query) {
    this.query = query;
    this.fetchData();
  }

  onFilterClean(query) {
    this.query = query;
    this.fetchData();
  }
}
